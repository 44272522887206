// Post wrapper
.wrapper.post {
    @include media-query($on-mobile) {
      padding-left: $spacing-half;
      padding-right: $spacing-half;
    }
  }
  
  // Post title
  .header {
    margin-top: 3.8em;
    margin-bottom: 3em;
  
    .tags {
      margin-left: 3px;
      letter-spacing: 0.5px;
  
      .tag {
        font-weight: $bold-weight;
        font-size: $small-font-size - 2;
  
        &:hover {
          text-decoration: none;
        }
      }
    }
  
    .header-title {
      font-size: 2em;
      line-height: 1.2;
      margin-top: 10px;
      margin-bottom: 20px;
  
      &.center {
        text-align: center;
      }
  
      @include media-query($on-mobile) {
        font-size: 1.9em;
      }
    }
  }
  
  // Post meta
  .post-meta {
    padding-top: 3px;
    line-height: 1.3;
    color: $gray;
  
    time {
      position: relative;
      margin-right: 1.5em;
  
      &::after {
        background: $light;
        bottom: 1px;
        content: " ";
        height: 2px;
        position: absolute;
        right: -20px;
        width: 12px;
      }
    }
  
    span[itemprop="author"] {
      border-bottom: 1px dotted $light;
    }
  }
  
  // Post content
  .page-content {
    padding-top: 8px;
  
    iframe {
      text-align: center;
    }
  
    figure {
      img {
        border-radius: 2px;
      }
  
      figcaption {
        margin-top: 5px;
        font-style: italic;
        font-size: $small-font-size;
      }
    }
  
    a {
      color: $text-link-blue;
      text-decoration: none;
      &[target="_blank"]::after {
        content: " \2197";
        font-size: $small-font-size;
        line-height: 0;
        position: relative;
        bottom: 5px;
        vertical-align: baseline;
      }
  
      &:hover {
        color: $text-link-blue-active;
      }
  
      &:focus {
        color: $text-link-blue;
      }
    }
  
    > p {
      margin: 0;
      padding-top: $spacing-full - 15;
      padding-bottom: $spacing-full - 15;
    }
  
    ul.task-list {
      list-style: none; 
      margin: 0;
  
      li::before {
        content: "";
      }
  
      li input[type="checkbox"] {
        margin-right: 10px;
      }
    }
  
    dl dt {
      font-weight: $bold-weight;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $black;
      font-weight: $bold-weight;
      margin-top: $spacing-full;
      margin-bottom: 0;
  
      &:hover {
        .anchor-head {
          color: $text-link-blue;
          opacity: 1;
        }
      }
  
      .anchor-head {
        position: relative;
        opacity: 0;
        outline: none;
  
        &::before {
          content: "#";
          position: absolute;
          right: -3px;
          width: 1em;
          font-weight: $bold-weight;
        }
      }
    }
  
    h1 {
      @include relative-font-size(1.5);
    }
  
    h2 {
      @include relative-font-size(1.375);
    }
  
    h3 {
      @include relative-font-size(1.25);
      border-bottom: 1px solid $light;
      padding-bottom: 4px;
    }
  
    h4 {
      @include relative-font-size(1.1);
    }
  
    h5 {
      @include relative-font-size(1);
    }
  
    h6 {
      @include relative-font-size(0.875);
    }
  }
  
  .post-nav {
    display: flex;
    position: relative;
    margin-top: 5em;
    border-top: 1px solid $light;
    line-height: 1.4;
  
    .post-nav-item {
      border-bottom: 0;
      font-weight: $bold-weight;
      padding-bottom: 10px;
  
      .post-title {
        color: $black;
      }
  
      &:hover,
      &:focus {
        .post-title {
          color: $text-link-blue-active;
          opacity: 0.9;
        }
      }
  
      .nav-arrow {
        font-weight: $normal-weight;
        font-size: $small-font-size;
        color: $gray;
        margin-bottom: 3px;
      }
  
      width: 50%;
      padding-top: 10px;
      text-decoration: none;
      box-sizing: border-box;
  
      &:nth-child(odd) {
        padding-left: 0;
        padding-right: 20px;
      }
  
      &:nth-child(even) {
        text-align: right;
        padding-right: 0;
        padding-left: 20px;
      }
    }
  
    @include media-query($on-mobile) {
      display: block;
      font-size: $small-font-size;
  
      .post-nav-item {
        display: block;
        width: 100%;
      }
  
      .post-nav-item:nth-child(even) {
        border-left: 0;
        padding-left: 0;
        border-top: 1px solid $light;
      }
    }
  }
  
  .post-updated-at {
    font-family: "Ubuntu mono", "monospace";
  }